import React from "react";
import { rowRenderer } from "../../utils/helper";

const PrintQuran = React.forwardRef((props, ref) => {
  const {
    data,
    language,
    showTranslation,
    showTafseer,
    showQols,
    showReferences,
    showArabic,
  } = props;
  return (
    <div ref={ref} className="col-12">
      {data.map((x, index) =>
        rowRenderer(
          index,
          x,
          language,
          showTranslation,
          showTafseer,
          showQols,
          showReferences,
          showArabic
        )
      )}
    </div>
  );
});

export default PrintQuran;
