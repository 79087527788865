import React, { Component } from "react";

class index extends Component {
  componentDidMount() {
    const { setVerseList } = this.props;
    setVerseList([]);
  }

  render() {
    return (
      <div style={{ minHeight: "100vh", marginTop: "6rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6">
              <h5>Contributors</h5>
              <br />
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Asad ( Developer)</td>
                    <td>Ali Naveed (Audio editor)</td>
                  </tr>
                  <tr>
                    <td>Dr Ali ( Guidance and proof reading)</td>
                    <td>Qamber Ali (Melbourne)</td>
                  </tr>
                  <tr>
                    <td>Ahsan (Developer)</td>
                    <td>Basit</td>
                  </tr>
                  <tr>
                    <td>Ali Raza (scanning of published material)</td>
                    <td>Juniad (Melbourne)</td>
                  </tr>
                  <tr>
                    <td>Jarri (proof reading)</td>
                    <td>Kumail</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <h5>Helpers in Proofing and other stuff</h5>
              <br />
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Fatima</td>
                    <td>Iffat hussain Shireen</td>
                  </tr>
                  <tr>
                    <td>Ali</td>
                    <td>Zain</td>
                  </tr>
                  <tr>
                    <td>Seema</td>
                    <td>Hamza</td>
                  </tr>
                  <tr>
                    <td>Khizr</td>
                    <td>Sidra</td>
                  </tr>
                  <tr>
                    <td>Yousra</td>
                    <td>Dr Fatima</td>
                  </tr>
                  <tr>
                    <td>Ms khuhro</td>
                    <td>Taha</td>
                  </tr>
                  <tr>
                    <td>Komal</td>
                    <td>Haroon</td>
                  </tr>
                  <tr>
                    <td>Syed Muhammad Rizvi</td>
                    <td>Dr Moin fatima</td>
                  </tr>
                  <tr>
                    <td>Hussain Abbas</td>
                    <td>Ali Fayyaz</td>
                  </tr>
                  <tr>
                    <td>Sadaf</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-12 col-sm-6 col-md-6">
              <h5>Please recite Fatiha</h5>
              <br />
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>Moulana Anis ul Hasnain</td>
                    <td>Uzma</td>
                  </tr>
                  <tr>
                    <td>Huzoor begum</td>
                    <td>Shane Hasan Khan</td>
                  </tr>
                  <tr>
                    <td>Mohsin Hasan Khan</td>
                    <td>Shahid Hasan Khan</td>
                  </tr>
                  <tr>
                    <td>Suriya bano</td>
                    <td>Mrs Shahnaz Ashraf</td>
                  </tr>
                  <tr>
                    <td>Mrs Anees Syeda</td>
                    <td>Syed Irshad Hussain</td>
                  </tr>
                  <tr>
                    <td>Qamar Abbas</td>
                    <td>Qasim Abbas</td>
                  </tr>
                  <tr>
                    <td>Syed Khurshed Haider</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default index;
