import React, { Component } from "react";
import HubspotForm from "react-hubspot-form";

class index extends Component {
  componentDidMount() {
    const { setVerseList } = this.props;
    setVerseList([]);
  }

  render() {
    return (
      <div style={{ minHeight: "100vh", marginTop: "6rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <HubspotForm
                portalId="19643783"
                formId="0c0c1c72-2fd8-4865-bf72-9cf67fb31fc5"
                onSubmit={() => console.log("Submit!")}
                loading={<div>Loading...</div>}
              />
              <script
                charset="utf-8"
                type="text/javascript"
                src="//js.hsforms.net/forms/shell.js"
              ></script>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default index;
