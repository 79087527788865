import React from "react";
import { Link } from "react-router-dom";

const index = () => {
  return (
    <footer className="page-footer footer">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-sm-10 col-lg-10 col-12 offset-md-1 offset-sm-1 offset-lg-1">
            <div className="row">
              <div className="col-md-2 col-sm-4 col-xs-12 footer-links">
                <p className="footer-options-heading">
                  <span>Navigate</span>
                </p>
                <ul className="footer-options">
                  <li>
                    <Link to="/about">
                      <span>About us</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy">
                      <span>Privacy Policy</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us">
                      <span>Contact us</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contributors">
                      <span>Contributors</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12 footer-links">
                <p className="footer-options-heading">
                  <span>Useful sites</span>
                </p>
                <ul className="footer-options">
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://theislam360.com/"
                    >
                      Islam 360
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.drhasanrizvi.org/"
                    >
                      Dr Hassan Rizvi
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12 footer-links">
                <p className="footer-options-heading">
                  <span>OTHER LINKS</span>
                </p>
                <ul className="footer-options">
                  {/* <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://google.com/"
                    >
                      Sitemap
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://google.com/"
                    >
                      SURAH YASIN, YASEEN (يس)
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://google.com/"
                    >
                      AYAT AL-KURSI (آية الكرسي)
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12 footer-links">
                <p className="montserrat text-color-primary">
                  <span>quranemubeen.org is a project by Dr. Hasan Rizvi.</span>
                </p>
                <p className="montserrat text-color-primary">
                  © 2020 Quranemubeen.org
                  <span> All Rights Reserved</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default index;
