import React from "react";
import { Link } from "react-router-dom";

const TopicsList = ({ filQuraanTopics }) => {
  return (
    <div>
      <h4 className="text-muted tab-heading">
        <span>Ale Muhammad a.s fil Quran</span>
      </h4>
      <div className="row sans-serif">
        <ul className="list-unstyled col-md-12">
          {filQuraanTopics.map((item) => (
            <li key={item.englishTopic} className="list-item">
              <Link
                className="list-item-link"
                to={`/fil-quran/topic/english/${item.englishTopic}`}
              >
                <div className="row">
                  <div className="col-12 list-column text-center font-weight-bold">
                    <span style={{ fontSize: 18 }}>{item.englishTopic}</span>
                  </div>
                  <div className="col-12 list-column text-center list-item-subtitle">
                    <span
                      className="text-uppercase"
                      style={{ fontSize: 22, fontFamily: "Alvi Nastaleeq" }}
                    >
                      {item.urduTopic}
                    </span>
                  </div>
                  <div className="col-12 list-column text-left list-item-subtitle">
                    {item.subTopics.length > 0 && (
                      <>
                        <div className="row">
                          <div className="col-12 text-center">
                            <span style={{ fontSize: 16 }}>Sub Topics</span>
                            <hr />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <ul>
                              {item.subTopics.map((subTopic) => (
                                <li
                                  style={{ fontSize: 13 }}
                                  key={subTopic.englishSubTopic}
                                >
                                  {subTopic.englishSubTopic}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="col-6 text-right">
                            <ul style={{ direction: "rtl" }}>
                              {item.subTopics.map((subTopic) => (
                                <li
                                  style={{
                                    fontSize: 16,
                                    fontFamily: "Alvi Nastaleeq",
                                  }}
                                  key={subTopic.urduSubTopic}
                                >
                                  {subTopic.urduSubTopic}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TopicsList;
