/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class index extends Component {
  render() {
    const {
      showLanguageOption,
      language,
      handleLanguageChanged,
      verseList,
      selectVerse,
    } = this.props;
    return (
      <nav className="montserrat navbar fixed-top navbar-expand-lg navbar-light bg-white">
        <Link className="navbar-brand" to="/">
          <img
            src={require("../../assets/imgs/logo.png")}
            style={{ height: 50 }}
            alt="Logo"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {verseList.length > 0 && (
              <li className="nav-item dropdown custom-dropdown-menu">
                <a className="nav-link dropdown-toggle" data-toggle="dropdown">
                  Go to Verse
                </a>
                <div className="dropdown-menu">
                  {verseList.map((x, index) => (
                    <a
                      key={`goto-verse-${index}`}
                      className="dropdown-item"
                      onClick={() => selectVerse(x.split(":")[2])}
                    >
                      {x.split(":")[0]}:{x.split(":")[1]}
                    </a>
                  ))}
                </div>
              </li>
            )}
            {showLanguageOption && (
              <>
                <li>
                  <div
                    onClick={() => handleLanguageChanged("urdu")}
                    className="custom-control custom-checkbox"
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={language === "urdu"}
                      onClick={() => {}}
                      onChange={() => {}}
                    />
                    <label onClick={() => {}} className="custom-control-label">
                      Urdu
                    </label>
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => handleLanguageChanged("english")}
                    className="ml-3 custom-control custom-checkbox"
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={language === "english"}
                      onClick={() => {}}
                      onChange={() => {}}
                    />
                    <label onClick={() => {}} className="custom-control-label">
                      English
                    </label>
                  </div>
                </li>
              </>
            )}
          </ul>
          <ul className="nav navbar-nav ml-auto">
            <li className="nav-item active">
              <Link className="nav-link" to="/contributors">
                Contributors
              </Link>
            </li>

            <li className="nav-item active">
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" to="/contact-us">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

index.propTypes = {
  language: PropTypes.string.isRequired,
  handleLanguageChanged: PropTypes.func.isRequired,
};

export default index;
