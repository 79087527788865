import React from "react";

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const formatReferences = (tafseer) => {
  let html = [];
  while (tafseer.includes("&&")) {
    let firstPart = tafseer.substr(0, tafseer.indexOf("&&"));
    html.push(<span>{firstPart}</span>);
    tafseer = tafseer.substr(tafseer.indexOf("&&") + 2);
    let reference = tafseer.substr(0, tafseer.indexOf("&&"));
    html.push(
      <>
        <br />
        <span style={{ borderBottom: "1.5px solid black" }}>{reference}</span>
        <br />
      </>
    );
    tafseer = tafseer.substr(tafseer.indexOf("&&") + 2);
  }
  html.push(<span>{tafseer}</span>);
  for (let index = 0; index < html.length; index++) {
    let element = html[index];
    if (element.type === "span") {
      tafseer = element.props.children;
      if (tafseer.includes("<<")) {
        html.splice(index, 1);
        let loopingFactor = 0;
        while (tafseer.includes("<<")) {
          let firstPart = tafseer.substr(0, tafseer.indexOf("<<"));
          html = insert(html, index + loopingFactor, <span>{firstPart}</span>);
          tafseer = tafseer.substr(tafseer.indexOf("<<") + 2);
          let arabic = tafseer.substr(0, tafseer.indexOf(">>"));
          html = insert(
            html,
            index + 1 + loopingFactor,
            <>
              <br />
              <span
                style={{
                  fontFamily: "noorehira",
                  fontSize: 24,
                  direction: "rtl",
                }}
              >
                {arabic}
              </span>
              <br />
            </>
          );
          tafseer = tafseer.substr(tafseer.indexOf(">>") + 2);
          loopingFactor += 2;
        }
        html = insert(html, index + loopingFactor, <span>{tafseer}</span>);
      }
    }
  }
  for (let index = 0; index < html.length; index++) {
    const element = html[index];
    if (element.type === "span") {
      tafseer = element.props.children;
      if (tafseer.includes("@@")) {
        html.splice(index, 1);
        let loopingFactor = 0;
        while (tafseer.includes("@@")) {
          let firstPart = tafseer.substr(0, tafseer.indexOf("@@"));
          html = insert(html, index + loopingFactor, <span>{firstPart}</span>);
          tafseer = tafseer.substr(tafseer.indexOf("@@") + 2);
          let translation = tafseer.substr(0, tafseer.indexOf("@@"));
          html = insert(
            html,
            index + 1 + loopingFactor,
            <>
              <br />
              <span style={{ fontSize: 22, direction: "rtl" }}>
                {translation}
              </span>
              <br />
            </>
          );
          tafseer = tafseer.substr(tafseer.indexOf("@@") + 2);
          loopingFactor += 2;
        }
        html = insert(html, index + loopingFactor, <span>{tafseer}</span>);
      }
    }
  }
  for (let index = 0; index < html.length; index++) {
    const element = html[index];
    if (element.type === "span") {
      tafseer = element.props.children;
      if (tafseer.includes("{{")) {
        html.splice(index, 1);
        let loopingFactor = 0;
        while (tafseer.includes("{{")) {
          let firstPart = tafseer.substr(0, tafseer.indexOf("{{"));
          html = insert(html, index + loopingFactor, <span>{firstPart}</span>);
          tafseer = tafseer.substr(tafseer.indexOf("{{") + 2);
          let qol = tafseer.substr(0, tafseer.indexOf("}}"));
          html = insert(
            html,
            index + 1 + loopingFactor,
            <>
              <br />
              <span style={{ fontSize: 24, direction: "rtl" }}>{qol}</span>
              <br />
            </>
          );
          tafseer = tafseer.substr(tafseer.indexOf("}}") + 2);
          loopingFactor += 2;
        }
        html = insert(html, index + loopingFactor, <span>{tafseer}</span>);
      }
    }
  }
  for (let index = 0; index < html.length; index++) {
    const element = html[index];
    if (element.type === "span") {
      tafseer = element.props.children;
      if (tafseer.includes("[[")) {
        let firstPart = tafseer.substr(0, tafseer.indexOf("[["));
        tafseer = tafseer.substr(tafseer.indexOf("[[") + 2);
        let shair = tafseer.substr(0, tafseer.indexOf("]]")).trim().split("۔");
        tafseer = tafseer.substr(tafseer.indexOf("]]") + 2);
        html[index] = (
          <React.Fragment key={`reference-${index}`}>
            <span>
              {firstPart
                .replace("&&", " ")
                .replace("{{", " ")
                .replace("}}", " ")
                .replace("@@", " ")
                .replace("<<", " ")
                .replace(">>", " ")}
            </span>
            <br />
            <span style={{ fontSize: 28, direction: "rtl" }}>{shair[0]}</span>
            <br />
            <span style={{ fontSize: 28, direction: "rtl" }}>{shair[1]}</span>
            <br />
            <span>
              {tafseer
                .replace("&&", " ")
                .replace("{{", " ")
                .replace("}}", " ")
                .replace("@@", " ")
                .replace("<<", " ")
                .replace(">>", " ")}
            </span>
          </React.Fragment>
        );
      } else {
        html[index] = (
          <span key={`reference-${index}`}>
            {tafseer
              .replace("&&", " ")
              .replace("{{", " ")
              .replace("}}", " ")
              .replace("@@", " ")
              .replace("<<", " ")
              .replace(">>", " ")}
          </span>
        );
      }
    }
  }
  return html;
};

export const rowRenderer = (
  index,
  item,
  language,
  showTranslation,
  showTafseer,
  showQols,
  showReferences,
  showArabic
) => {
  let qols;
  if (IsJsonString(item.qol)) {
    qols = JSON.parse(item.qol);
    if (IsJsonString(qols)) {
      qols = JSON.parse(qols);
    } else {
      qols = qols
        .split("[")
        .join("")
        .split("]")
        .join("")
        .split("\\")
        .join("")
        .split('"')
        .join("");
      qols = JSON.parse(`["${qols}"]`);
    }
  }
  let englishQols;
  if (IsJsonString(item.englishQol)) {
    englishQols = JSON.parse(item.englishQol);
    if (IsJsonString(englishQols)) {
      englishQols = JSON.parse(englishQols);
    } else {
      englishQols = englishQols
        .split("[")
        .join("")
        .split("]")
        .join("")
        .split("\\")
        .join("")
        .split('"')
        .join("");
      englishQols = JSON.parse(`["${englishQols}"]`);
    }
  }
  let references;
  if (IsJsonString(item.reference)) {
    references = JSON.parse(item.reference);
    if (IsJsonString(references)) {
      references = JSON.parse(references);
    } else {
      references = references
        .split("[")
        .join("")
        .split("]")
        .join("")
        .split("\\")
        .join("")
        .split('"')
        .join("");
      references = JSON.parse(`["${references}"]`);
    }
  }
  let englishReferences;
  if (IsJsonString(item.englishReference)) {
    englishReferences = JSON.parse(item.englishReference);
    if (IsJsonString(englishReferences)) {
      englishReferences = JSON.parse(englishReferences);
    } else {
      englishReferences = englishReferences
        .split("[")
        .join("")
        .split("]")
        .join("")
        .split("\\")
        .join("")
        .split('"')
        .join("");
      englishReferences = JSON.parse(`["${englishReferences}"]`);
    }
  }
  return (
    <div key={index} className="p-2" id={`row-${index}`}>
      {Number(item.ayatNo) === 0 && (
        <>
          <div className="row mt-5">
            <div className="col-12">
              <p
                className="text-center mb-0"
                style={{
                  fontFamily: "Alvi Nastaleeq",
                  fontSize: 30,
                  direction: "rtl",
                }}
              >
                فضیلت
              </p>
              <p
                className="text-right mb-3"
                style={{
                  fontFamily: "Alvi Nastaleeq",
                  fontSize: 22,
                  direction: "rtl",
                }}
              >
                {item.fazilatSurah.split('"').join("")
                  ? formatReferences(
                      item.fazilatSurah.split('"').join("").split("\\").join("")
                    )
                  : ""}
              </p>
            </div>
          </div>
        </>
      )}
      <div className="hover row mb-3 pt-2">
        <div className="col-md-12 col-sm-12">
          {showArabic && (
            <>
              <p
                className="text-right mb-0"
                style={{
                  fontFamily: "noorehira",
                  fontSize: 26,
                  lineHeight: 2,
                  direction: "rtl",
                }}
              >
                {item.arabic.split('"').join("")}
              </p>
              <br />
            </>
          )}
          {language === "urdu" && showTranslation && (
            <>
              <p
                className="text-right mb-0"
                style={{
                  fontFamily: "Alvi Nastaleeq",
                  fontSize: 26,
                  lineHeight: 1.5,
                  direction: "rtl",
                }}
              >
                {item.urduTranslation.split('"').join("")}
              </p>
              <br />
            </>
          )}
          {language === "english" && showTranslation && (
            <p
              className="text-left mb-0"
              style={{
                fontSize: 26,
                lineHeight: 1.5,
                direction: "ltr",
              }}
            >
              {item.englishTranslation.trim().split('"').join("")}
            </p>
          )}
          {showTafseer &&
            language === "urdu" &&
            showQols === false &&
            showReferences === false && (
              <p
                className="text-right mb-0"
                style={{
                  fontFamily: "Alvi Nastaleeq",
                  fontSize: 22,
                  direction: "rtl",
                }}
              >
                {item.tafseer.split('"').join("")
                  ? formatReferences(item.tafseer.split('"').join(""))
                  : ""}
              </p>
            )}
          {showTafseer && language === "english" && (
            <p
              className="text-left mb-0"
              style={{
                fontSize: 18,
                direction: "ltr",
              }}
            >
              {item.englishTafseer.split('"').join("")
                ? formatReferences(item.englishTafseer.split('"').join(""))
                : ""}
            </p>
          )}
          {showQols && language === "urdu" && qols.length > 0 && (
            <ul className="mr-4">
              <p
                className="text-right mb-2"
                style={{
                  fontFamily: "Alvi Nastaleeq",
                  fontSize: 30,
                  direction: "rtl",
                }}
              >
                قول
              </p>
              {qols.map((x, ind) => (
                <li
                  key={`qol-index-${ind}`}
                  className="text-right"
                  style={{
                    fontFamily: "Alvi Nastaleeq",
                    fontSize: 22,
                    direction: "rtl",
                  }}
                >
                  {formatReferences(x)}
                </li>
              ))}
            </ul>
          )}
          {showQols && language === "english" && englishQols.length > 0 && (
            <ul>
              <p
                className="text-left mb-2"
                style={{
                  fontSize: 22,
                  direction: "ltr",
                }}
              >
                Sayings (Qols)
              </p>
              {englishQols.map((x, ind) => (
                <li
                  key={`qol-index-${ind}`}
                  className="text-right"
                  style={{
                    fontSize: 16,
                    direction: "ltr",
                  }}
                >
                  {formatReferences(x)}
                </li>
              ))}
            </ul>
          )}
          {showReferences && language === "urdu" && references.length > 0 && (
            <ul className="mr-4">
              <p
                className="text-right mb-2"
                style={{
                  fontFamily: "Alvi Nastaleeq",
                  fontSize: 30,
                  direction: "rtl",
                }}
              >
                حوالجات
              </p>
              {references.map((x, ind) => (
                <li
                  key={`ref-index-${ind}`}
                  className="text-right"
                  style={{
                    fontFamily: "Alvi Nastaleeq",
                    fontSize: 22,
                    direction: "rtl",
                  }}
                >
                  {formatReferences(x)}
                </li>
              ))}
            </ul>
          )}
          {showReferences &&
            language === "english" &&
            englishReferences.length > 0 && (
              <ul className="mr-4">
                <p
                  className="text-left mb-2"
                  style={{
                    fontSize: 22,
                    direction: "ltr",
                  }}
                >
                  References
                </p>
                {englishReferences.map((x, ind) => (
                  <li
                    key={`ref-eng-index-${ind}`}
                    className="text-left"
                    style={{
                      fontSize: 16,
                      direction: "ltr",
                    }}
                  >
                    {formatReferences(x)}
                  </li>
                ))}
              </ul>
            )}
          {item.description !== undefined && item.description !== null && (
            <>
              <p
                className="text-right mb-0"
                style={{
                  fontFamily: "Alvi Nastaleeq",
                  fontSize: 26,
                  lineHeight: 2,
                  direction: "rtl",
                  color: "#5184E7",
                }}
              >
                {item.description.split(":").join("").split('"').join("")}
              </p>
              <br />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
