import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import "react-virtualized/styles.css";
import Home from "./home";
import Footer from "./footer";
import Header from "./header";
import SurahWiseReader from "./surah-wise-reading";
import ParahWiseReader from "./parah-wise-reading";
import Contributors from "./contributors/index";
import ContactUs from "./contact-us/index";
import AboutUs from "./about-us/index";
import TopicReader from "./QasasUlQuran/readTopic";
import FilQuranTopicReader from "./FilQuran/readTopic";
import RoohUlQuranTopicReader from "./RoohUlQuran/readTopic";
import SearchResultsReader from "./searchResultsReader/searchResultsReader";
import PrivacyPolicy from "./privacy-policy/index";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "urdu",
      showLanguageOption: false,
      verseList: [],
      selectedVerse: -1,
    };
  }

  handleLanguageChanged = (language) => {
    this.setState({
      language,
    });
  };

  toggleLanguageOptionVisibility = (showLanguageOption) => {
    this.setState({
      showLanguageOption,
    });
  };

  setVerseList = (list) => {
    this.setState({
      verseList: list,
    });
  };

  selectVerse = (index) => {
    this.setState({
      selectedVerse: index,
    });
  };

  render() {
    const { language, showLanguageOption, verseList, selectedVerse } =
      this.state;
    return (
      <div>
        <Header
          showLanguageOption={showLanguageOption}
          language={language}
          verseList={verseList}
          selectVerse={this.selectVerse}
          handleLanguageChanged={this.handleLanguageChanged}
        />
        <Switch>
          <Route
            path="/"
            exact={true}
            render={() => <Home setVerseList={this.setVerseList} />}
          />
          <Route
            path="/contact-us"
            exact={true}
            render={() => <ContactUs setVerseList={this.setVerseList} />}
          />
          <Route
            path="/contributors"
            exact={true}
            render={() => <Contributors setVerseList={this.setVerseList} />}
          />
          <Route
            path="/about"
            exact={true}
            render={() => <AboutUs setVerseList={this.setVerseList} />}
          />
          <Route
            path="/privacy"
            exact={true}
            render={() => <PrivacyPolicy setVerseList={this.setVerseList} />}
          />
          <Route
            path="/surah/:chapterNumber"
            exact={true}
            render={() => (
              <SurahWiseReader
                toggleLanguageOptionVisibility={
                  this.toggleLanguageOptionVisibility
                }
                setVerseList={this.setVerseList}
                selectedVerse={selectedVerse}
                language={language}
              />
            )}
          />
          <Route
            path="/parah/:parahNumber"
            exact={true}
            render={() => (
              <ParahWiseReader
                toggleLanguageOptionVisibility={
                  this.toggleLanguageOptionVisibility
                }
                setVerseList={this.setVerseList}
                selectedVerse={selectedVerse}
                language={language}
              />
            )}
          />
          <Route
            path="/topic/:type/:topicName"
            exact={true}
            render={() => (
              <TopicReader
                toggleLanguageOptionVisibility={
                  this.toggleLanguageOptionVisibility
                }
                setVerseList={this.setVerseList}
                selectedVerse={selectedVerse}
                language={language}
              />
            )}
          />
          <Route
            path="/fil-quran/topic/:type/:topicName"
            exact={true}
            render={() => (
              <FilQuranTopicReader
                toggleLanguageOptionVisibility={
                  this.toggleLanguageOptionVisibility
                }
                setVerseList={this.setVerseList}
                selectedVerse={selectedVerse}
                language={language}
              />
            )}
          />
          <Route
            path="/rooh-ul-quran/topic/:type/:topicName"
            exact={true}
            render={() => (
              <RoohUlQuranTopicReader
                toggleLanguageOptionVisibility={
                  this.toggleLanguageOptionVisibility
                }
                setVerseList={this.setVerseList}
                selectedVerse={selectedVerse}
                language={language}
              />
            )}
          />
          <Route
            path="/search-results"
            exact={true}
            render={() => (
              <SearchResultsReader
                toggleLanguageOptionVisibility={
                  this.toggleLanguageOptionVisibility
                }
                setVerseList={this.setVerseList}
                selectedVerse={selectedVerse}
                language={language}
              />
            )}
          />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default App;
