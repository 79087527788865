/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from "axios";
import { SERVER_ENDPOINT } from "../../assets/config";
import { withRouter } from "react-router-dom";
import SurahList from "../surahs-list";
import ParahList from "../parahs-list";
import QasasUlQuranTopicsList from "../QasasUlQuran/topicsList";
import RoohUlQuranTopicsList from "../RoohUlQuran/topicsList";
import FilQuranTopicsList from "../FilQuran/topicsList";
import Juz from "../../assets/data/juz.json";
import Surahs from "../../assets/data/surahs.json";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "surah",
      qassasUlQuranTopics: [],
      roohUlQuranTopics: [],
      filQuraanTopics: [],
      language: "urdu",
      searchIn: "translation",
      onlySearchIn: "all",
      selectedParah: "",
      selectedSurah: "",
      query: "",
    };
  }

  componentDidMount() {
    const { setVerseList } = this.props;
    setVerseList([]);
    axios
      .get(`${SERVER_ENDPOINT}/qasas-ul-quraan/get-all-topics`)
      .then((response) => {
        let qassasUlQuranTopics = response.data.data.topics;
        this.setState({
          qassasUlQuranTopics,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${SERVER_ENDPOINT}/rooh-ul-quraan/get-all-topics`)
      .then((response) => {
        let roohUlQuranTopics = response.data.data.topics;
        this.setState({
          roohUlQuranTopics,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${SERVER_ENDPOINT}/fil-quraan/get-all-topics`)
      .then((response) => {
        let filQuraanTopics = response.data.data.topics;
        this.setState({
          filQuraanTopics,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const searchTerms = localStorage.getItem("SEARCH_TERMS");
    if (searchTerms !== null) {
      const data = JSON.parse(searchTerms);
      this.setState({
        language: data.language ? data.language : "urdu",
        searchIn: data.searchIn ? data.searchIn : "translation",
        onlySearchIn: data.onlySearchIn ? data.onlySearchIn : "all",
        selectedParah: data.selectedParah ? data.selectedParah : "",
        selectedSurah: data.selectedSurah ? data.selectedSurah : "",
        query: data.query ? data.query : "",
      });
    } else {
      this.setState({
        language: "urdu",
        searchIn: "translation",
        onlySearchIn: "all",
        selectedParah: "",
        selectedSurah: "",
        query: "",
      });
    }
  }

  switchTab = (tab) => {
    this.setState({
      tab: tab,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSearch = () => {
    const {
      language,
      searchIn,
      query,
      selectedParah,
      selectedSurah,
      onlySearchIn,
    } = this.state;
    axios
      .post(`${SERVER_ENDPOINT}/quraan/search`, {
        language: language,
        type: searchIn,
        query: query,
      })
      .then((response) => {
        let results = response.data.quraan;
        if (onlySearchIn === "parah") {
          results = results.filter((x) => x.parahNo === `${selectedParah}`);
        } else if (onlySearchIn === "surah") {
          results = results.filter((x) => x.surahNo === `${selectedSurah}`);
        }
        localStorage.setItem(
          "SEARCH_TERMS",
          JSON.stringify({
            language: this.state.language,
            searchIn: this.state.searchIn,
            onlySearchIn: this.state.onlySearchIn,
            selectedParah: this.state.selectedParah,
            selectedSurah: this.state.selectedSurah,
            query: this.state.query,
          })
        );
        this.props.history.push("/search-results", {
          searchResults: results,
          language: language,
          type: searchIn,
          query: query,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  selectLanguage = (event) => {
    this.setState({
      language: event.target.value,
    });
  };

  selectSearchIn = (event) => {
    this.setState({
      searchIn: event.target.value,
    });
  };

  selectOnlySearchIn = (event) => {
    this.setState({
      onlySearchIn: event.target.value,
    });
  };

  selectParah = (event) => {
    this.setState({
      selectedParah: event.target.value,
    });
  };

  selectSurah = (event) => {
    this.setState({
      selectedSurah: event.target.value,
    });
  };

  detectEnterPressed = (event) => {
    if (event.keyCode === 13) {
      this.handleSearch();
    }
  };

  render() {
    const {
      tab,
      language,
      searchIn,
      query,
      onlySearchIn,
      selectedParah,
      selectedSurah,
    } = this.state;
    return (
      <main role="main">
        <section
          className="jumbotron text-center hero"
          style={{
            backgroundImage: `url(${require("../../assets/imgs/hero-bg.png")})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1 text-center">
                <a className="hero-body" href="/">
                  <img
                    src="//assets-1f14.kxcdn.com/images/logo-lg-w.png"
                    className="hero-image"
                    alt="logo"
                  />
                </a>
                <div className="mt-4 right-inner-addon searchinput mb-2">
                  <input
                    type="search"
                    name="query"
                    value={query}
                    onChange={this.handleChange}
                    onKeyUp={this.detectEnterPressed}
                    style={{
                      fontFamily:
                        language === "english"
                          ? "Montserrat"
                          : language === "arabic"
                          ? "noorehira"
                          : language === "urdu"
                          ? "Alvi Nastaleeq"
                          : language === "roman"
                          ? "Montserrat"
                          : "Montserrat",
                      direction:
                        language === "english" || language === "roman"
                          ? "ltr"
                          : "rtl",
                    }}
                    placeholder={
                      language === "english"
                        ? "Islam, Prayer, Fasting etc"
                        : language === "arabic"
                        ? "الإسلام والصلاة والصوم إلخ"
                        : language === "urdu"
                        ? "اسلام ، نماز ، روزہ وغیرہ"
                        : language === "roman"
                        ? "Islam, Namaz, Roza etc"
                        : ""
                    }
                  />
                </div>
                <div className="text-left">
                  <div className="row mb-4">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      <label>Choose Language</label>
                      <select
                        className="form-control"
                        onChange={this.selectLanguage}
                        value={language}
                        placeholder="Please choose a language"
                      >
                        <option value="arabic">Arabic</option>
                        <option value="urdu">Urdu</option>
                        <option value="roman">Roman</option>
                      </select>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      <label>Search In</label>
                      <select
                        className="form-control"
                        onChange={this.selectSearchIn}
                        value={searchIn}
                        placeholder="Please choose where to search"
                      >
                        {language === "arabic" ? (
                          <option value="arabic">Quran Arabic</option>
                        ) : language === "roman" ? (
                          <>
                            <option value="arabic">Quran Arabic</option>
                            <option value="translation">Translation</option>
                          </>
                        ) : (
                          <>
                            <option value="translation">Translation</option>
                            <option value="tafseer">Tafseer</option>
                          </>
                        )}
                      </select>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                      <label>Only Search in</label>
                      <select
                        className="form-control"
                        onChange={this.selectOnlySearchIn}
                        value={onlySearchIn}
                        placeholder="Please choose in which part of quraan search"
                      >
                        <option value="all">All</option>
                        <option value="parah">Parah</option>
                        <option value="surah">Surah</option>
                      </select>
                    </div>
                  </div>
                  {onlySearchIn === "parah" && (
                    <div className="row mb-4">
                      <div className="col-12">
                        <label>Choose Parah</label>
                        <select
                          className="form-control"
                          onChange={this.selectParah}
                          value={selectedParah}
                          placeholder="Please choose a parah"
                        >
                          <option value="0">Choose Parah</option>
                          {Juz.map((x) => (
                            <option key={x.juz_name} value={x.juz_number}>
                              {x.juz_number} - {x.juz_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  {onlySearchIn === "surah" && (
                    <div className="row mb-4">
                      <div className="col-12">
                        <label>Choose Surah</label>
                        <select
                          className="form-control"
                          onChange={this.selectSurah}
                          value={selectedSurah}
                          placeholder="Please choose a surah"
                        >
                          <option value="0">Choose Surah</option>
                          {Surahs.map((x) => (
                            <option key={x.name_simple} value={x.id}>
                              {x.chapter_number} - {x.name_simple}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="row mb-4">
                    <div className="col-12">
                      <button
                        onClick={this.handleSearch}
                        type="button"
                        className="btn btn-light btn-md w-100"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-10 offset-1 col-xs-12 offset-xs-0">
              <div>
                <h4 className="text-muted quick-links quick-links-seperator">
                  <span>Read Quran</span>
                  <span>
                    <a onClick={() => this.switchTab("parah")}>Parah</a>
                  </span>
                  <span>
                    <a
                      className="quick-links-active"
                      onClick={() => this.switchTab("surah")}
                    >
                      Surah
                    </a>
                  </span>
                  <span>
                    <a onClick={() => this.switchTab("qassasulquran")}>
                      Qassas ul Quran
                    </a>
                  </span>
                  <span>
                    <a onClick={() => this.switchTab("roohulquraan")}>
                      Rooh ul Quran
                    </a>
                  </span>
                  <span>
                    <a onClick={() => this.switchTab("filquraan")}>
                      Ale Muhammad a.s fil Quran
                    </a>
                  </span>
                  {/* <span>
                    <a onClick={this.showTopicsTab}>Topics</a>
                  </span> */}
                  {/* <span>
                    <Link to="/surah/36">Surah Yasin (Yaseen)</Link>
                  </span> */}
                  {/* <span>
                    <Link to="/surah/55">Surah Ar-Rahman</Link>
                  </span> */}
                  {/* <span>
                    <Link to="/surah/67">Surah Al Mulk</Link>
                  </span> */}
                  {/* <span>
                    <Link to="/ayatul-kursi">Ayatul Kursi</Link>
                  </span> */}
                </h4>
              </div>
              {tab === "surah" && <SurahList />}
              {tab === "parah" && <ParahList />}
              {tab === "qassasulquran" && (
                <QasasUlQuranTopicsList
                  qassasUlQuranTopics={this.state.qassasUlQuranTopics}
                />
              )}
              {tab === "roohulquraan" && (
                <RoohUlQuranTopicsList
                  roohUlQuranTopics={this.state.roohUlQuranTopics}
                />
              )}
              {tab === "filquraan" && (
                <FilQuranTopicsList
                  filQuraanTopics={this.state.filQuraanTopics}
                />
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(index);
