import React, { Component } from "react";
import { Link } from "react-router-dom";
import Chapters from "../../assets/data/surahs.json";

class index extends Component {
  render() {
    return (
      <div>
        <h4 className="text-muted tab-heading">
          <span>SURAHS (CHAPTERS)</span>
        </h4>
        <div className="row sans-serif">
          <ul className="list-unstyled col-md-4">
            {Chapters.slice(0, 38).map((chapter) => (
              <li key={chapter.id} className="list-item">
                <Link
                  className="list-item-link"
                  to={`/surah/${chapter.chapter_number}`}
                >
                  <div className="row">
                    <div className="col-2 list-column text-muted">
                      {chapter.chapter_number}
                    </div>
                    <div className="col-10 list-column font-weight-bold">
                      {chapter.name_simple}
                    </div>
                    <div className="col-10 list-column offset-2 text-right list-item-subtitle">
                      <span
                        className={`icon-surah${chapter.chapter_number}`}
                      ></span>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
          <ul className="list-unstyled col-md-4">
            {Chapters.slice(38, 76).map((chapter) => (
              <li key={chapter.id} className="list-item">
                <Link
                  className="list-item-link"
                  to={`/surah/${chapter.chapter_number}`}
                >
                  <div className="row">
                    <div className="col-2 list-column text-muted">
                      {chapter.chapter_number}
                    </div>
                    <div className="col-10 list-column font-weight-bold">
                      {chapter.name_simple}
                    </div>
                    <div className="col-10 list-column offset-2 text-right list-item-subtitle">
                      <span
                        className={`icon-surah${chapter.chapter_number}`}
                      ></span>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
          <ul className="list-unstyled col-md-4">
            {Chapters.slice(76, 114).map((chapter) => (
              <li key={chapter.id} className="list-item">
                <Link
                  className="list-item-link"
                  to={`/surah/${chapter.chapter_number}`}
                >
                  <div className="row">
                    <div className="col-2 list-column text-muted">
                      {chapter.chapter_number}
                    </div>
                    <div className="col-10 list-column font-weight-bold">
                      {chapter.name_simple}
                    </div>
                    <div className="col-10 list-column offset-2 text-right list-item-subtitle">
                      <span
                        className={`icon-surah${chapter.chapter_number}`}
                      ></span>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default index;
