/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { debounce } from "debounce";
import HubspotForm from "react-hubspot-form";
import { SERVER_ENDPOINT } from "../../assets/config";
import { pad } from "../../assets/utils";
import Juz from "../../assets/data/juz.json";
import PrintQuran from "../print/printQuran";
import ReactToPrint from "react-to-print";
import { FaPrint } from "react-icons/fa";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageNumber: 1,
      copyIndex: -1,
      audioPlayerSurahAndAyatNo: "",
      language: "urdu",
      showTranslation: true,
      showTafseer: false,
      showLoader: false,
      isAudioPlaying: false,
      index: -1,
      showQols: false,
      showReferences: false,
      showArabic: true,
      playbackSpeed: 1,
      modalShow: "",
      display: "none",
      infoModalShow: "",
      infoDisplay: "none",
      isFazilaatPlaying: false,
    };
  }

  toggleTranslation = () => {
    this.setState({
      showTranslation: !this.state.showTranslation,
    });
  };

  toggleTafseer = () => {
    this.setState({
      showTafseer: !this.state.showTafseer,
      showQols: false,
      showReferences: false,
    });
  };

  toggleQol = () => {
    this.setState({
      showQols: !this.state.showQols,
    });
  };

  toggleReferences = () => {
    this.setState({
      showReferences: !this.state.showReferences,
    });
  };

  toggleArabic = () => {
    this.setState({
      showArabic: !this.state.showArabic,
    });
  };

  audio = null;

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
    const { toggleLanguageOptionVisibility, setVerseList, match } = this.props;
    const { parahNumber } = match.params;
    if (parahNumber) {
      let item = Juz.find((x) => Number(x.id) === Number(parahNumber));
      if (!item.is_verified) {
        this.showInfoPopup();
      }
      this.setState({
        showLoader: true,
      });
      axios
        .post(`${SERVER_ENDPOINT}/quraan/get`, {
          parahNo: parahNumber,
        })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              data: response.data.quraan,
              index: -1,
              showLoader: false,
            });
            for (let index = 0; index < response.data.quraan.length; index++) {
              const element = response.data.quraan[index];
              if (element.englishTafseer.split('"').join("").length !== 0) {
                toggleLanguageOptionVisibility(true);
                break;
              }
            }
            let verseList = response.data.quraan.map(
              (x, index) => `${x.surahNo}:${x.ayatNo}:${index}`
            );
            setVerseList(verseList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.audio && this.audio !== null) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedVerse } = this.props;
    if (
      selectedVerse !== -1 &&
      prevProps.selectedVerse !== this.props.selectedVerse
    ) {
      const { pageNumber } = this.state;
      let versePageNumber = Math.ceil(selectedVerse / 10);
      if (versePageNumber < pageNumber) {
        this.scrollToElement(selectedVerse);
      } else {
        this.setState({
          pageNumber: versePageNumber,
        });
        setTimeout(() => {
          this.scrollToElement(selectedVerse);
        }, 50);
      }
    }
  }

  handlePlaybackSpeedChanged = (event) => {
    this.setState({
      playbackSpeed: Number(event.target.value),
    });
    if (this.audio && this.audio !== null) {
      this.audio.playbackRate = Number(event.target.value);
    }
  };

  playFazilaatAudio = (surahNo) => {
    let fazilaatUrl = `https://firebasestorage.googleapis.com/v0/b/quran-e-mubeen.appspot.com/o/fazilat%2F${pad(
      surahNo,
      3
    )}%2F${pad(surahNo, 3)}.mp3?alt=media`;
    let indexToScrollTo = this.state.data.findIndex(
      (x) => x.surahNo === surahNo && x.ayatNo === "0"
    );
    if (this.audio && this.audio !== null) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
    this.audio = new Audio(fazilaatUrl);
    this.audio.addEventListener("error", () => {
      this.moveToNext(indexToScrollTo - 1);
    });
    this.setState({
      isAudioPlaying: true,
      isFazilaatPlaying: true,
    });
    this.audio.play();
    this.audio.addEventListener("ended", () => {
      this.moveToNext(indexToScrollTo - 1);
    });
  };

  playAudio = (index, suratNo, ayatNo) => {
    const { showTafseer, showArabic, showTranslation, playbackSpeed } =
      this.state;
    let arabicUrl = `https://firebasestorage.googleapis.com/v0/b/quran-e-mubeen.appspot.com/o/arabic%2F${pad(
      suratNo,
      3
    )}%2F${pad(suratNo, 3)}${pad(ayatNo, 3)}.mp3?alt=media`;
    let translationUrl = `https://firebasestorage.googleapis.com/v0/b/quran-e-mubeen.appspot.com/o/translations%2F${pad(
      suratNo,
      3
    )}%2F${pad(suratNo, 2)}_${pad(ayatNo, 2)}.mp3?alt=media`;
    let tafseerUrl = `https://firebasestorage.googleapis.com/v0/b/tafseerquran-65c46.appspot.com/o/Tafseer%2F${pad(
      suratNo,
      3
    )}%2F${pad(suratNo, 2)}_${pad(ayatNo, 2)}.mp3?alt=media`;
    this.setState({
      audioPlayerSurahAndAyatNo: `${suratNo}-${ayatNo}`,
      isFazilaatPlaying: false,
      index,
    });
    if (this.audio && this.audio !== null) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
    if (showArabic) {
      this.audio = new Audio(arabicUrl);
      this.audio.addEventListener("error", () => {
        this.moveToNext(index);
      });
      this.audio.playbackRate = playbackSpeed;
      this.audio.play();
      this.setState({
        isAudioPlaying: true,
      });
      this.audio.addEventListener("ended", () => {
        if (showTranslation) {
          this.playTranslationAudio(translationUrl, index, tafseerUrl);
        } else {
          if (showTafseer) {
            this.playTafseerAudio(tafseerUrl, index);
          } else {
            this.moveToNext(index);
          }
        }
      });
    } else {
      if (showTranslation) {
        this.playTranslationAudio(translationUrl, index, tafseerUrl);
      } else {
        if (showTafseer) {
          this.playTafseerAudio(tafseerUrl, index);
        } else {
          this.moveToNext(index);
        }
      }
    }
  };

  playTranslationAudio(translationUrl, index, tafseerUrl) {
    const { playbackSpeed, showTafseer } = this.state;
    this.audio = new Audio(translationUrl);
    this.audio.addEventListener("error", () => {
      this.moveToNext(index);
    });
    this.audio.playbackRate = playbackSpeed;
    this.audio.play();
    this.setState({
      isAudioPlaying: true,
    });
    this.audio.addEventListener("ended", () => {
      if (showTafseer) {
        this.playTafseerAudio(tafseerUrl, index);
      } else {
        this.moveToNext(index);
      }
    });
  }

  playTafseerAudio(tafseerUrl, index) {
    const { playbackSpeed } = this.state;
    this.audio = new Audio(tafseerUrl);
    this.audio.addEventListener("error", () => {
      this.moveToNext(index);
    });
    this.audio.playbackRate = playbackSpeed;
    this.audio.play();
    this.setState({
      isAudioPlaying: true,
    });
    this.audio.addEventListener("ended", () => {
      this.moveToNext(index);
    });
  }

  moveToNext(index) {
    if (index < this.state.data.length) {
      let nextItem = this.state.data[index + 1];
      let ayatNo = Number(nextItem.ayatNo);
      let surahNo = Number(nextItem.surahNo);
      this.scrollToElement(index + 1);
      this.playAudio(index + 1, surahNo, ayatNo);
    }
  }

  moveForward = () => {
    const { index, data } = this.state;
    if (index < data.length) {
      let nextItem = data[index + 1];
      let ayatNo = Number(nextItem.ayatNo);
      let surahNo = Number(nextItem.surahNo);
      this.scrollToElement(index + 1);
      let element = document.getElementById(`row-${index + 1}`);
      const y = element.getBoundingClientRect().top + window.scrollY;
      window.scroll({
        top: y - 100,
        behavior: "smooth",
      });
      this.playAudio(index + 1, surahNo, ayatNo);
    }
  };

  moveFazilaat = () => {
    const { data } = this.state;
    let surahNo = Number(data[0].surahNo);
    let ayatNo = Number(data[0].ayatNo);
    let element = document.getElementById(`row-${0}`);
    const y = element.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y - 100,
      behavior: "smooth",
    });
    this.playAudio(0, surahNo, ayatNo);
  };

  moveBack = () => {
    const { audioPlayerSurahAndAyatNo, data } = this.state;
    let firstItem = data[0];
    let surahAndAyatNo = audioPlayerSurahAndAyatNo.split("-");
    let index = data.findIndex(
      (x) => x.surahNo === surahAndAyatNo[0] && x.ayatNo === surahAndAyatNo[1]
    );
    let ayatNo = Number(surahAndAyatNo[1]);
    if (index !== -1) {
      if (ayatNo - 1 >= Number(firstItem.ayatNo)) {
        let element = document.getElementById(`row-${index - 1}`);
        const y = element.getBoundingClientRect().top + window.scrollY;
        window.scroll({
          top: y - 100,
          behavior: "smooth",
        });
        this.playAudio(index - 1, surahAndAyatNo[0], ayatNo - 1);
      }
    }
  };

  stopAudio = () => {
    this.audio.pause();
    this.setState({
      audioPlayerSurahAndAyatNo: "",
      isAudioPlaying: false,
      isFazilaatPlaying: false,
    });
  };

  pauseAudio = () => {
    if (this.audio && this.audio !== null) {
      this.audio.pause();
      this.setState({
        isAudioPlaying: false,
      });
    }
  };

  resumeAudio = () => {
    if (this.audio && this.audio !== null) {
      this.audio.play();
      this.setState({
        isAudioPlaying: true,
      });
    }
  };

  copyContent = (index) => {
    const { data } = this.state;
    this.setState({
      copyIndex: index,
    });
    navigator.clipboard.writeText(
      `${data[index].surahNo}:${data[index].ayatNo} ${data[index].arabic
        .split('"')
        .join("")}`
    );
    setTimeout(() => {
      this.setState({
        copyIndex: -1,
      });
    }, 500);
  };

  shareContentToWhatsapp = (index) => {
    const { data } = this.state;
    let text = `${data[index].surahNo}:${data[index].ayatNo} ${data[
      index
    ].arabic
      .split('"')
      .join("")}     ${data[index].urduTranslation.split('"').join("")}`;
    let url = "";
    if (this.mobileCheck()) {
      url = `whatsapp://send?text=${text}`;
    } else {
      url = `https://wa.me/?text=${text}`;
    }
    window.open(url, "_blank");
  };

  shareContentToFacebook = () => {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=600`;
    let url = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
    window.open(url, "_blank", params);
  };

  mobileCheck = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  };

  formatReferences = (tafseer) => {
    let html = [];
    while (tafseer.includes("&&")) {
      let firstPart = tafseer.substr(0, tafseer.indexOf("&&"));
      html.push(<span>{firstPart}</span>);
      tafseer = tafseer.substr(tafseer.indexOf("&&") + 2);
      let reference = tafseer.substr(0, tafseer.indexOf("&&"));
      html.push(
        <>
          <br />
          <span style={{ borderBottom: "1.5px solid black" }}>{reference}</span>
          <br />
        </>
      );
      tafseer = tafseer.substr(tafseer.indexOf("&&") + 2);
    }
    html.push(<span>{tafseer}</span>);
    for (let index = 0; index < html.length; index++) {
      let element = html[index];
      if (element.type === "span") {
        tafseer = element.props.children;
        if (tafseer.includes("<<")) {
          html.splice(index, 1);
          let loopingFactor = 0;
          while (tafseer.includes("<<")) {
            let firstPart = tafseer.substr(0, tafseer.indexOf("<<"));
            html = this.insert(
              html,
              index + loopingFactor,
              <span>{firstPart}</span>
            );
            tafseer = tafseer.substr(tafseer.indexOf("<<") + 2);
            let arabic = tafseer.substr(0, tafseer.indexOf(">>"));
            html = this.insert(
              html,
              index + 1 + loopingFactor,
              <>
                <br />
                <span
                  style={{
                    fontFamily: "noorehira",
                    fontSize: 24,
                    direction: "rtl",
                  }}
                >
                  {arabic}
                </span>
                <br />
              </>
            );
            tafseer = tafseer.substr(tafseer.indexOf(">>") + 2);
            loopingFactor += 2;
          }
          html = this.insert(
            html,
            index + loopingFactor,
            <span>{tafseer}</span>
          );
        }
      }
    }
    for (let index = 0; index < html.length; index++) {
      const element = html[index];
      if (element.type === "span") {
        tafseer = element.props.children;
        if (tafseer.includes("@@")) {
          html.splice(index, 1);
          let loopingFactor = 0;
          while (tafseer.includes("@@")) {
            let firstPart = tafseer.substr(0, tafseer.indexOf("@@"));
            html = this.insert(
              html,
              index + loopingFactor,
              <span>{firstPart}</span>
            );
            tafseer = tafseer.substr(tafseer.indexOf("@@") + 2);
            let translation = tafseer.substr(0, tafseer.indexOf("@@"));
            html = this.insert(
              html,
              index + 1 + loopingFactor,
              <>
                <br />
                <span style={{ fontSize: 22, direction: "rtl" }}>
                  {translation}
                </span>
                <br />
              </>
            );
            tafseer = tafseer.substr(tafseer.indexOf("@@") + 2);
            loopingFactor += 2;
          }
          html = this.insert(
            html,
            index + loopingFactor,
            <span>{tafseer}</span>
          );
        }
      }
    }
    for (let index = 0; index < html.length; index++) {
      const element = html[index];
      if (element.type === "span") {
        tafseer = element.props.children;
        if (tafseer.includes("{{")) {
          html.splice(index, 1);
          let loopingFactor = 0;
          while (tafseer.includes("{{")) {
            let firstPart = tafseer.substr(0, tafseer.indexOf("{{"));
            html = this.insert(
              html,
              index + loopingFactor,
              <span>{firstPart}</span>
            );
            tafseer = tafseer.substr(tafseer.indexOf("{{") + 2);
            let qol = tafseer.substr(0, tafseer.indexOf("}}"));
            html = this.insert(
              html,
              index + 1 + loopingFactor,
              <>
                <br />
                <span style={{ fontSize: 24, direction: "rtl" }}>{qol}</span>
                <br />
              </>
            );
            tafseer = tafseer.substr(tafseer.indexOf("}}") + 2);
            loopingFactor += 2;
          }
          html = this.insert(
            html,
            index + loopingFactor,
            <span>{tafseer}</span>
          );
        }
      }
    }
    for (let index = 0; index < html.length; index++) {
      const element = html[index];
      if (element.type === "span") {
        tafseer = element.props.children;
        if (tafseer.includes("[[")) {
          let firstPart = tafseer.substr(0, tafseer.indexOf("[["));
          tafseer = tafseer.substr(tafseer.indexOf("[[") + 2);
          let shair = tafseer
            .substr(0, tafseer.indexOf("]]"))
            .trim()
            .split("۔");
          tafseer = tafseer.substr(tafseer.indexOf("]]") + 2);
          html[index] = (
            <React.Fragment key={`reference-${index}`}>
              <span>
                {firstPart
                  .replace("&&", " ")
                  .replace("{{", " ")
                  .replace("}}", " ")
                  .replace("@@", " ")
                  .replace("<<", " ")
                  .replace(">>", " ")}
              </span>
              <br />
              <span style={{ fontSize: 28, direction: "rtl" }}>{shair[0]}</span>
              <br />
              <span style={{ fontSize: 28, direction: "rtl" }}>{shair[1]}</span>
              <br />
              <span>
                {tafseer
                  .replace("&&", " ")
                  .replace("{{", " ")
                  .replace("}}", " ")
                  .replace("@@", " ")
                  .replace("<<", " ")
                  .replace(">>", " ")}
              </span>
            </React.Fragment>
          );
        } else {
          html[index] = (
            <span key={`reference-${index}`}>
              {tafseer
                .replace("&&", " ")
                .replace("{{", " ")
                .replace("}}", " ")
                .replace("@@", " ")
                .replace("<<", " ")
                .replace(">>", " ")}
            </span>
          );
        }
      }
    }
    return html;
  };

  insert = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];

  IsJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  _rowRenderer = (index, item) => {
    const {
      copyIndex,
      audioPlayerSurahAndAyatNo,
      showTranslation,
      showTafseer,
      showQols,
      showReferences,
      showArabic,
      isFazilaatPlaying,
    } = this.state;
    const { language } = this.props;
    let qols;
    if (this.IsJsonString(item.qol)) {
      qols = JSON.parse(item.qol);
      if (this.IsJsonString(qols)) {
        qols = JSON.parse(qols);
      } else {
        qols = qols
          .split("[")
          .join("")
          .split("]")
          .join("")
          .split("\\")
          .join("")
          .split('"')
          .join("");
        qols = JSON.parse(`["${qols}"]`);
      }
    }
    let englishQols;
    if (this.IsJsonString(item.englishQol)) {
      englishQols = JSON.parse(item.englishQol);
      if (this.IsJsonString(englishQols)) {
        englishQols = JSON.parse(englishQols);
      } else {
        englishQols = englishQols
          .split("[")
          .join("")
          .split("]")
          .join("")
          .split("\\")
          .join("")
          .split('"')
          .join("");
        englishQols = JSON.parse(`["${englishQols}"]`);
      }
    }
    let references;
    if (this.IsJsonString(item.reference)) {
      references = JSON.parse(item.reference);
      if (this.IsJsonString(references)) {
        references = JSON.parse(references);
      } else {
        references = references
          .split("[")
          .join("")
          .split("]")
          .join("")
          .split("\\")
          .join("")
          .split('"')
          .join("");
        references = JSON.parse(`["${references}"]`);
      }
    }
    let englishReferences;
    if (this.IsJsonString(item.englishReference)) {
      englishReferences = JSON.parse(item.englishReference);
      if (this.IsJsonString(englishReferences)) {
        englishReferences = JSON.parse(englishReferences);
      } else {
        englishReferences = englishReferences
          .split("[")
          .join("")
          .split("]")
          .join("")
          .split("\\")
          .join("")
          .split('"')
          .join("");
        englishReferences = JSON.parse(`["${englishReferences}"]`);
      }
    }
    return (
      <div key={index} className="p-2" id={`row-${index}`}>
        {Number(item.ayatNo) === 0 && (
          <>
            <div className="row mt-5">
              <div className="col-2">
                <div className="d-flex justify-content-between">
                  {isFazilaatPlaying ? (
                    <a onClick={this.stopAudio} className="text-muted">
                      <p className="mb-0">
                        <span
                          style={{
                            backgroundColor: "#308100",
                            color: "#fff",
                            fontSize: 12,
                          }}
                          className="label reader-tag mb-2"
                        >
                          <i className="fa fa-stop vertical-align-middle"></i>{" "}
                          <span>Stop</span>
                        </span>
                      </p>
                    </a>
                  ) : (
                    <a
                      onClick={() => this.playFazilaatAudio(item.surahNo)}
                      className="text-muted"
                    >
                      <p className="mb-0">
                        <span
                          style={{
                            backgroundColor: "#308100",
                            color: "#fff",
                            fontSize: 12,
                          }}
                          className="label reader-tag mb-2"
                        >
                          <i className="fa fa-play vertical-align-middle"></i>{" "}
                          <span>Play</span>
                        </span>
                      </p>
                    </a>
                  )}
                </div>
              </div>
              <div className="col-10">
                <p
                  className="text-center mb-0"
                  style={{
                    fontFamily: "Alvi Nastaleeq",
                    fontSize: 30,
                    direction: "rtl",
                  }}
                >
                  فضیلت
                </p>
                <p
                  className="text-right mb-3"
                  style={{
                    fontFamily: "Alvi Nastaleeq",
                    fontSize: 22,
                    direction: "rtl",
                  }}
                >
                  {item.fazilatSurah.split('"').join("")
                    ? this.formatReferences(
                        item.fazilatSurah
                          .split('"')
                          .join("")
                          .split("\\")
                          .join("")
                      )
                    : ""}
                </p>
              </div>
            </div>
          </>
        )}
        <div
          className={
            audioPlayerSurahAndAyatNo === `${item.surahNo}-${item.ayatNo}`
              ? "hover hover-selected row mb-3 pt-2"
              : "hover row mb-3 pt-2"
          }
        >
          <div className="col-md-12 col-sm-12">
            <div className="row mb-2 d-none b-sm-flex d-md-flex d-lg-flex d-xl-flex">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <a>
                    <p className="mb-0">
                      <span
                        style={{
                          backgroundColor: "#308100",
                          color: "#fff",
                          fontSize: 12,
                        }}
                        className="label reader-tag mb-2"
                      >
                        {item.surahNo}:{item.ayatNo}
                      </span>
                    </p>
                  </a>
                  <a>
                    <p className="mb-0">
                      <span
                        style={{
                          backgroundColor: "#308100",
                          color: "#fff",
                          fontSize: 12,
                        }}
                        className="label reader-tag mb-2"
                      >
                        Parah: {item.parahNo}
                      </span>
                    </p>
                  </a>
                  {audioPlayerSurahAndAyatNo ===
                  `${item.surahNo}-${item.ayatNo}` ? (
                    <a onClick={this.stopAudio}>
                      <p className="mb-0">
                        <span
                          style={{
                            backgroundColor: "#308100",
                            color: "#fff",
                            fontSize: 12,
                          }}
                          className="label reader-tag mb-2"
                        >
                          <i className="fa fa-stop vertical-align-middle"></i>{" "}
                          <span>Stop</span>
                        </span>
                      </p>
                    </a>
                  ) : (
                    <a
                      onClick={() =>
                        this.playAudio(index, item.surahNo, item.ayatNo)
                      }
                    >
                      <p className="mb-0">
                        <span
                          className="label reader-tag mb-2"
                          style={{
                            backgroundColor: "#308100",
                            color: "#fff",
                            fontSize: 12,
                          }}
                        >
                          <i className="fa fa-play vertical-align-middle"></i>{" "}
                          <span>Play</span>
                        </span>
                      </p>
                    </a>
                  )}
                  <a
                    onClick={() => this.copyContent(index)}
                    className="text-muted"
                  >
                    <p className="mb-0">
                      <span
                        className="label reader-tag mb-2"
                        style={{
                          backgroundColor: "#308100",
                          color: "#fff",
                          fontSize: 12,
                        }}
                      >
                        <i className="fa fa-copy vertical-align-middle"></i>{" "}
                        <span>{index === copyIndex ? "Copied" : "Copy"}</span>
                      </span>
                    </p>
                  </a>
                  <a
                    onClick={() => this.feedbackPopup(index)}
                    className="text-muted"
                  >
                    <p className="mb-0">
                      <span
                        style={{
                          backgroundColor: "#308100",
                          color: "#fff",
                          fontSize: 12,
                        }}
                        className="label reader-tag mb-2"
                      >
                        <i className="fa fa-edit vertical-align-middle"></i>{" "}
                        <span>Feedback</span>
                      </span>
                    </p>
                  </a>
                  <a
                    onClick={() => this.shareContentToWhatsapp(index)}
                    className="text-muted"
                  >
                    <p className="mb-0">
                      <span className="label reader-tag mb-2">
                        <img
                          style={{ height: 18, width: 18 }}
                          src={require("../../assets/imgs/whatsapp.svg")}
                          alt="whatsapp"
                        />
                      </span>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="row mb-2 d-flex b-sm-none d-md-none d-lg-none d-xl-none">
              <div className="col-6 col-sm-6 col-md-3">
                <a>
                  <p className="mb-0">
                    <span
                      style={{
                        backgroundColor: "#308100",
                        color: "#fff",
                        fontSize: 12,
                      }}
                      className="label reader-tag mb-2"
                    >
                      {item.surahNo}:{item.ayatNo}
                    </span>
                  </p>
                </a>
              </div>
              <div className="col-6 col-sm-6 col-md-3 text-right">
                <a>
                  <p className="mb-0">
                    <span
                      style={{
                        backgroundColor: "#308100",
                        color: "#fff",
                        fontSize: 12,
                      }}
                      className="label reader-tag mb-2"
                    >
                      Parah: {item.parahNo}
                    </span>
                  </p>
                </a>
              </div>
              <br />
              <div className="col-6 col-sm-6 col-md-3">
                {audioPlayerSurahAndAyatNo ===
                `${item.surahNo}-${item.ayatNo}` ? (
                  <a onClick={this.stopAudio} className="text-muted">
                    <p className="mb-0">
                      <span
                        style={{
                          backgroundColor: "#308100",
                          color: "#fff",
                          fontSize: 12,
                        }}
                        className="label reader-tag mb-2"
                      >
                        <i className="fa fa-stop vertical-align-middle"></i>{" "}
                        <span>Stop</span>
                      </span>
                    </p>
                  </a>
                ) : (
                  <a
                    onClick={() =>
                      this.playAudio(index, item.surahNo, item.ayatNo)
                    }
                    className="text-muted"
                  >
                    <p className="mb-0">
                      <span
                        style={{
                          backgroundColor: "#308100",
                          color: "#fff",
                          fontSize: 12,
                        }}
                        className="label reader-tag mb-2"
                      >
                        <i className="fa fa-play vertical-align-middle"></i>{" "}
                        <span>Play</span>
                      </span>
                    </p>
                  </a>
                )}
              </div>
              <div className="col-6 col-sm-6 col-md-3 text-right">
                <a
                  onClick={() => this.copyContent(index)}
                  className="text-muted"
                >
                  <p className="mb-0">
                    <span
                      style={{
                        backgroundColor: "#308100",
                        color: "#fff",
                        fontSize: 12,
                      }}
                      className="label reader-tag mb-2"
                    >
                      <i className="fa fa-copy vertical-align-middle"></i>{" "}
                      <span>{index === copyIndex ? "Copied" : "Copy"}</span>
                    </span>
                  </p>
                </a>
              </div>
              <br />
              <div className="col-6 col-sm-6 col-md-3">
                <a
                  onClick={() => this.feedbackPopup(index)}
                  className="text-muted"
                >
                  <p className="mb-0">
                    <span
                      style={{
                        backgroundColor: "#308100",
                        color: "#fff",
                        fontSize: 12,
                      }}
                      className="label reader-tag mb-2"
                    >
                      <i className="fa fa-edit vertical-align-middle"></i>{" "}
                      <span>Feedback</span>
                    </span>
                  </p>
                </a>
              </div>
              <div className="col-6 col-sm-6 col-md-3 text-right">
                <a
                  onClick={() => this.shareContentToWhatsapp(index)}
                  className="text-muted"
                >
                  <p className="mb-0">
                    <span className="label reader-tag mb-2">
                      <img
                        style={{ height: 18, width: 18 }}
                        src={require("../../assets/imgs/whatsapp.svg")}
                        alt="whatsapp"
                      />
                    </span>
                  </p>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6 col-md-3">
                <div
                  onClick={this.toggleArabic}
                  className="custom-control custom-checkbox"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={showArabic}
                    onClick={() => {}}
                    onChange={() => {}}
                  />
                  <label onClick={() => {}} className="custom-control-label">
                    Arabic
                  </label>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <div
                  onClick={this.toggleTranslation}
                  className="custom-control custom-checkbox"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={showTranslation}
                    onClick={() => {}}
                    onChange={() => {}}
                  />
                  <label onClick={() => {}} className="custom-control-label">
                    Translation
                  </label>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <div
                  onClick={this.toggleTafseer}
                  className="custom-control custom-checkbox"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={showTafseer}
                    onClick={() => {}}
                    onChange={() => {}}
                  />
                  <label onClick={() => {}} className="custom-control-label">
                    Tafseer
                  </label>
                </div>
              </div>
            </div>
            {showTafseer && (
              <div className="row">
                <div className="col-6 col-sm-6 col-md-3">
                  <div
                    onClick={this.toggleQol}
                    className="custom-control custom-checkbox"
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      onClick={() => {}}
                      checked={showQols}
                      onChange={() => {}}
                    />
                    <label onClick={() => {}} className="custom-control-label">
                      Sayings/Ayat
                    </label>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3">
                  <div
                    onClick={this.toggleReferences}
                    className="custom-control custom-checkbox"
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      onClick={() => {}}
                      checked={showReferences}
                      onChange={() => {}}
                    />
                    <label onClick={() => {}} className="custom-control-label">
                      References
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
          <br />
          <br />
          <div className="col-md-12 col-sm-12">
            {showArabic && (
              <>
                <p
                  className="text-right mb-0"
                  style={{
                    fontFamily: "noorehira",
                    fontSize: 26,
                    lineHeight: 2,
                    direction: "rtl",
                  }}
                >
                  {item.arabic.split('"').join("")}
                </p>
                <br />
              </>
            )}
            {language === "urdu" && showTranslation && (
              <>
                <p
                  className="text-right mb-0"
                  style={{
                    fontFamily: "Alvi Nastaleeq",
                    fontSize: 26,
                    lineHeight: 1.5,
                    direction: "rtl",
                  }}
                >
                  {item.urduTranslation.split('"').join("")}
                </p>
                <br />
              </>
            )}
            {language === "english" && showTranslation && (
              <p
                className="text-left mb-0"
                style={{
                  fontSize: 26,
                  lineHeight: 1.5,
                  direction: "ltr",
                }}
              >
                {item.englishTranslation.trim().split('"').join("")}
              </p>
            )}
            {showTafseer &&
              language === "urdu" &&
              showQols === false &&
              showReferences === false && (
                <p
                  className="text-right mb-0"
                  style={{
                    fontFamily: "Alvi Nastaleeq",
                    fontSize: 22,
                    direction: "rtl",
                  }}
                >
                  {item.tafseer.split('"').join("")
                    ? this.formatReferences(item.tafseer.split('"').join(""))
                    : ""}
                </p>
              )}
            {showTafseer && language === "english" && (
              <p
                className="text-left mb-0"
                style={{
                  fontSize: 18,
                  direction: "ltr",
                }}
              >
                {item.englishTafseer.split('"').join("")
                  ? this.formatReferences(
                      item.englishTafseer.split('"').join("")
                    )
                  : ""}
              </p>
            )}
            {showQols && language === "urdu" && qols.length > 0 && (
              <ul className="mr-4">
                <p
                  className="text-right mb-2"
                  style={{
                    fontFamily: "Alvi Nastaleeq",
                    fontSize: 30,
                    direction: "rtl",
                  }}
                >
                  قول
                </p>
                {qols.map((x, ind) => (
                  <li
                    key={`qol-index-${ind}`}
                    className="text-right"
                    style={{
                      fontFamily: "Alvi Nastaleeq",
                      fontSize: 22,
                      direction: "rtl",
                    }}
                  >
                    {this.formatReferences(x)}
                  </li>
                ))}
              </ul>
            )}
            {showQols && language === "english" && englishQols.length > 0 && (
              <ul>
                <p
                  className="text-left mb-2"
                  style={{
                    fontSize: 22,
                    direction: "ltr",
                  }}
                >
                  Sayings (Qols)
                </p>
                {englishQols.map((x, ind) => (
                  <li
                    key={`qol-index-${ind}`}
                    className="text-right"
                    style={{
                      fontSize: 16,
                      direction: "ltr",
                    }}
                  >
                    {this.formatReferences(x)}
                  </li>
                ))}
              </ul>
            )}
            {showReferences && language === "urdu" && references.length > 0 && (
              <ul className="mr-4">
                <p
                  className="text-right mb-2"
                  style={{
                    fontFamily: "Alvi Nastaleeq",
                    fontSize: 30,
                    direction: "rtl",
                  }}
                >
                  حوالجات
                </p>
                {references.map((x, ind) => (
                  <li
                    key={`ref-index-${ind}`}
                    className="text-right"
                    style={{
                      fontFamily: "Alvi Nastaleeq",
                      fontSize: 22,
                      direction: "rtl",
                    }}
                  >
                    {this.formatReferences(x)}
                  </li>
                ))}
              </ul>
            )}
            {showReferences &&
              language === "english" &&
              englishReferences.length > 0 && (
                <ul className="mr-4">
                  <p
                    className="text-left mb-2"
                    style={{
                      fontSize: 22,
                      direction: "ltr",
                    }}
                  >
                    References
                  </p>
                  {englishReferences.map((x, ind) => (
                    <li
                      key={`ref-eng-index-${ind}`}
                      className="text-left"
                      style={{
                        fontSize: 16,
                        direction: "ltr",
                      }}
                    >
                      {this.formatReferences(x)}
                    </li>
                  ))}
                </ul>
              )}
            {item.description !== undefined && item.description !== null && (
              <>
                <p
                  className="text-right mb-0"
                  style={{
                    fontFamily: "Alvi Nastaleeq",
                    fontSize: 26,
                    lineHeight: 2,
                    direction: "rtl",
                    color: "#5184E7",
                  }}
                >
                  {item.description.split(":").join("").split('"').join("")}
                </p>
                <br />
              </>
            )}
          </div>
          <div
            className="col-12"
            style={{ backgroundColor: "#e2e2e2", height: 1 }}
          />
        </div>
      </div>
    );
  };

  handleScroll = () => {
    const { showLoader, data, pageNumber } = this.state;
    let contentArea = document.getElementById("content-area");
    if (!this.debouncedFn) {
      this.debouncedFn = debounce(() => {
        const { data, pageNumber } = this.state;
        if (window.scrollY > contentArea.clientHeight - 500) {
          if (pageNumber < Math.ceil(data.length / 10)) {
            this.setState({
              pageNumber: pageNumber + 1,
              showLoader: false,
            });
          } else {
            this.setState({
              showLoader: false,
            });
          }
        }
      }, 50);
    }
    if (
      window.scrollY > contentArea.clientHeight - 500 &&
      showLoader === false
    ) {
      if (pageNumber < Math.ceil(data.length / 10)) {
        this.setState({
          showLoader: true,
        });
      } else {
        this.setState({
          showLoader: false,
        });
      }
    }
    this.debouncedFn();
  };

  scrollToElement(index) {
    let element = document.getElementById(`row-${index}`);
    const y = element.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y - 100,
      behavior: "smooth",
    });
  }

  feedbackPopup = (index) => {
    this.setState({
      modalShow: "show",
      display: "block",
      index,
    });
  };

  closePopup = () => {
    this.setState({
      modalShow: "",
      display: "none",
    });
  };

  showInfoPopup = () => {
    this.setState({
      infoModalShow: "show",
      infoDisplay: "block",
    });
  };

  closeInfoPopup = () => {
    this.setState({
      infoModalShow: "",
      infoDisplay: "none",
    });
  };

  render() {
    const {
      data,
      pageNumber,
      showLoader,
      audioPlayerSurahAndAyatNo,
      isAudioPlaying,
      index,
      playbackSpeed,
      modalShow,
      display,
      infoModalShow,
      infoDisplay,
      isFazilaatPlaying,
      language,
      showArabic,
      showQols,
      showReferences,
      showTafseer,
      showTranslation,
    } = this.state;
    return (
      <>
        {!showLoader && (
          <div
            className={"modal overlay-custom fade " + infoModalShow}
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
            style={{ display: infoDisplay }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Important</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.closeInfoPopup}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p className="bold text-center">
                    This is a Beta version, If you find any mistake in
                    transalations or tafseer. Please compare them to{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.drhasanrizvi.org/TAFSEER-E%20QURAN.html"
                    >
                      Published version
                    </a>{" "}
                    and report it using feedback
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {modalShow === "show" && (
          <div
            className={"modal fade " + modalShow}
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
            style={{ display: display }}
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Feedback</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.closePopup}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <HubspotForm
                    portalId="19643783"
                    formId="6d0a9aca-9093-43ef-a647-a0202de93411"
                    onSubmit={() => console.log("Submit!")}
                    onFormReady={() => {
                      var iframe = document.getElementById("hs-form-iframe-0");
                      var element =
                        iframe.contentWindow.document.getElementsByName(
                          "subject"
                        )[0];
                      const dataItem = data[index];
                      element.value = `Surah-${dataItem.surahNo}:Ayat-${dataItem.ayatNo}`;
                    }}
                    loading={<div>Loading...</div>}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {showLoader && (
          <div className="overlay-custom">
            <div
              style={{ width: "3rem", height: "3rem" }}
              className="spinner-border text-success"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div style={{ minHeight: "100vh" }} className="mt-5">
          <div className="row mt-4">
            <div className="col-2 col-xs-2 offset-10 offset-xs-10">
              <ReactToPrint
                trigger={() => (
                  <a
                    onClick={() => {
                      if (this.printDiv) {
                      }
                    }}
                    className="text-muted"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <p className="mb-0">
                      <span
                        className="label reader-tag mb-2"
                        style={{
                          backgroundColor: "#308100",
                          color: "#fff",
                          fontSize: 12,
                        }}
                      >
                        <FaPrint />
                        <span>Print</span>
                      </span>
                    </p>
                  </a>
                )}
                content={() => this.printDiv}
              />
            </div>
            <div
              id="content-area"
              className="col-10 col-xs-12 offset-1 offset-xs-0"
            >
              {data
                .slice(0, 10 * pageNumber)
                .map((x, index) => this._rowRenderer(index, x))}
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <PrintQuran
            ref={(el) => (this.printDiv = el)}
            data={data}
            language={language}
            showTranslation={showTranslation}
            showTafseer={showTafseer}
            showQols={showQols}
            showReferences={showReferences}
            showArabic={showArabic}
          />
        </div>
        {audioPlayerSurahAndAyatNo !== "" && (
          <div className="false main-audio-player-side">
            <ul className="list-inline audio-controls-container">
              <li className="audio-control-item">
                <span className="english undefined">
                  <span>
                    Ayat:{" "}
                    {audioPlayerSurahAndAyatNo === ""
                      ? "-"
                      : audioPlayerSurahAndAyatNo.split("-")[1]}
                  </span>
                </span>
              </li>
              <li className="audio-control-item">
                <a
                  disabled={index === 0}
                  onClick={this.moveBack}
                  tabIndex="-1"
                  className={
                    index === 0
                      ? "pointer audio-control disabled"
                      : "pointer audio-control"
                  }
                >
                  <i className="fa fa-step-backward"></i>
                </a>
              </li>
              <li className="audio-control-item">
                <a
                  tabIndex="-1"
                  style={{ paddingLeft: isAudioPlaying ? 6 : 8 }}
                  className="pointer text-center audio-control-play audio-control"
                  onClick={() =>
                    isAudioPlaying ? this.pauseAudio() : this.resumeAudio()
                  }
                >
                  {isAudioPlaying ? (
                    <i className="fa fa-pause"></i>
                  ) : (
                    <i className="fa fa-play"></i>
                  )}
                </a>
              </li>
              <li className="audio-control-item">
                <a
                  disabled={index === data.length - 1}
                  onClick={this.moveForward}
                  tabIndex="-1"
                  className={
                    index === data.length - 1
                      ? "pointer audio-control disabled"
                      : "pointer audio-control"
                  }
                >
                  <i className="fa fa-step-forward"></i>
                </a>
              </li>
              <li className="audio-control-item">
                <select
                  onChange={this.handlePlaybackSpeedChanged}
                  className="form-control"
                  value={playbackSpeed}
                >
                  <option value="1">1x</option>
                  <option value="1.5">1.5x</option>
                  <option value="2">2x</option>
                </select>
              </li>
            </ul>
          </div>
        )}
        {isFazilaatPlaying && (
          <div className="false main-audio-player-side">
            <ul className="list-inline audio-controls-container">
              <li className="audio-control-item">
                <span className="english undefined">
                  <span>Ayat: Fazilat</span>
                </span>
              </li>
              <li className="audio-control-item">
                <a
                  disabled={index === 0}
                  tabIndex="-1"
                  className="pointer audio-control disabled"
                >
                  <i className="fa fa-step-backward"></i>
                </a>
              </li>
              <li className="audio-control-item">
                <a
                  tabIndex="-1"
                  style={{ paddingLeft: isAudioPlaying ? 6 : 8 }}
                  className="pointer text-center audio-control-play audio-control"
                  onClick={() =>
                    isAudioPlaying ? this.pauseAudio() : this.resumeAudio()
                  }
                >
                  {isAudioPlaying ? (
                    <i className="fa fa-pause"></i>
                  ) : (
                    <i className="fa fa-play"></i>
                  )}
                </a>
              </li>
              <li className="audio-control-item">
                <a
                  onClick={this.moveFazilaat}
                  tabIndex="-1"
                  className="pointer audio-control"
                >
                  <i className="fa fa-step-forward"></i>
                </a>
              </li>
              <li className="audio-control-item">
                <select
                  onChange={this.handlePlaybackSpeedChanged}
                  className="form-control"
                  value={playbackSpeed}
                >
                  <option value="1">1x</option>
                  <option value="1.5">1.5x</option>
                  <option value="2">2x</option>
                </select>
              </li>
            </ul>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(index);
