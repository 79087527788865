import React, { Component } from "react";
import Swiper from "react-id-swiper";
import "swiper/swiper-bundle.css";

const params = {
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
  spaceBetween: 30,
};

class index extends Component {
  componentDidMount() {
    const { setVerseList } = this.props;
    setVerseList([]);
  }

  render() {
    return (
      <div style={{ minHeight: "100vh", marginTop: "6rem" }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Swiper {...params}>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/1.jpg")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/2.jpg")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/3.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/4.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/5.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/6.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/7.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/8.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/9.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/10.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/11.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/12.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/13.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/14.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/15.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/16.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/17.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/18.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/19.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/20.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/21.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/22.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/23.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/24.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/25.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/26.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/27.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/28.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/29.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/30.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/31.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/32.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/33.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/34.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/35.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/36.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/37.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/38.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/39.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/40.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/41.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/42.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/43.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/44.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/45.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/46.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/47.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/48.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/49.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/50.png")}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    alt="img"
                    style={{ height: 500, objectFit: "cover" }}
                    src={require("../../assets/imgs/dr-hassan/51.png")}
                  />
                </div>
              </Swiper>
              <div className="swiper-pagination"></div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-12">
              <h5 className="text-center">
                Mufasir e Quran Dr. S M HASAN RIZVI
              </h5>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-12">
              <h5>Education</h5>
              <ul style={{ listStyle: "none" }}>
                <li>
                  PhD from Jamia Arabia, Karachi (recognized by Jamia Al-Azhar,
                  Egypt)
                </li>
                <li>M.A. (Economics) from Karachi University</li>
                <li>
                  B.A (Hons) Arabic / Persian Literature from University of
                  Sindh
                </li>
                <li>Madresah Mashareal Uloom, Hyderabad Sindh</li>
                <li>
                  Darse-Sukhan-Rani from Ayatullah Falsafi of Mashad, Iran
                </li>
                <li>
                  Darse-Tafseer from Ayatullah Mahdi Pooya, Allama Rasheed
                  Turabi, Khateeb-e-Azam Maulana Syed Mohd. Dehalvi, Maulana
                  Zafar Hasan, Ayatullah Shariati, Thiqatul Islam Maulana Samar
                  Hasan Zaidi, Allama ibn Hasan Jarchavi
                </li>
              </ul>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <h5>Wakeel and Ijaza</h5>
              <ul style={{ listStyle: "none" }}>
                <li>Current Wakeel of Grand Ayatollah Ali Sistani Najaf</li>
                <li>Ex. Wakeel of Grand Ayatollah Al Khoe of Najaf</li>
              </ul>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <h5>Works</h5>
              <ul style={{ listStyle: "none" }}>
                <li>
                  Research Scholar, Dy. Director Islamic Research Center Karachi
                </li>
                <li>Ex-Professor Allama Iqbal Open University</li>
                <li>
                  Director, Research for Islamic Studies, Karachi University
                </li>
                <li>President Academy of Quranic Studies</li>
                <li>Advisor Pak Muharram Education Trust</li>
                <li>Advisor Adamjee Insurance Co. Ltd</li>
                <li>
                  Reciting central Majalis at U.K., UAE, USA, Canada, Africa,
                  Hong Kong, Mascat, India and Pakistan since 1967
                </li>
                <li>Khateeb Mahfil-e-Shah-e-Khorasaan Karachi</li>
              </ul>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <h5>Works</h5>
              <ol>
                <li>
                  Quran-e-Mubeen – simplest &amp; easiest possible translation
                  of the Holy Quran, in day to day spoken Urdu language
                </li>
                <li>
                  Khulasa – tul – Tafaseer – summary of tafaseers from all the
                  important schools of thoughts, of Islam, including
                  tafseer-e-Ahlaybait (30 volumes)
                </li>
                <li>
                  Usool-e-kafi – Easiest and simplest translation of the
                  selected text (Urdu &amp; English)
                </li>
                <li>
                  Rooh – e - Quran – summary of selected topics of Holy Quran
                  (Urdu &amp; English)
                </li>
                <li>
                  Rooh aur Mout ki Haqeeqat – The available details to
                  understand death and rooh (spirit)
                </li>
                <li>Kalam Shah Bhittai – Selection &amp; Urdu Translation </li>
                <li>Word by word English translation of Holy Quran</li>
                <li>
                  Introduction of Shia believes from the authentic Sunni books
                  (A practical effort for inter sectarian unity){" "}
                </li>
                <li>Tafseer of 30 most important Suras of Holy Quran</li>
                <li>
                  Subjective Tafseer (commentary) of 100 (most important)
                  subjects of Holy Quran{" "}
                </li>
                <li>
                  Asbaat o Marefat –e- Khuda (Proves of existence and
                  recognition of God) – in the light of the modern research{" "}
                </li>
                <li>Recognition of Ahl-e-Bayt from authentic Sunni books </li>
                <li>
                  Recognition of Imam Mehdi A.S. &amp; our responsibilities{" "}
                </li>
                <li>
                  Selection from Sawaeqe Muhreqa (Wilayat-e-Hazrat Ali A.S.)
                </li>
                <li>
                  Usool –e- Din (Pillars / Rules of Islam) – Subjective Tafseer
                  , describing the principals in simple Urdu with the help of
                  Holy Quran, Hadiths and modern emerging sciences
                </li>
                <li>
                  Selected and the simplest Urdu translation of Saheef-e-Kamila
                  – Prayers of Imam Zain-ul-Abedin A.S.{" "}
                </li>
                <li>
                  Selected Urdu translation of Uyoon Akhbar-e-Raza (A.S.) – the
                  life &amp; saying of Imam Ali Raza A.S.
                </li>
                <li>
                  Tafseer-e-Qassasul Quran - Commentary of Stories of Holy
                  Quran, along with the different point of views from various
                  schools of thoughts
                </li>
                <li>
                  Selected and subject wise English translation of Nahjul -
                  Balagha
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default index;
