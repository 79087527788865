import React, { Component } from "react";
import { Link } from "react-router-dom";
import Juz from "../../assets/data/juz.json";

class index extends Component {
  render() {
    return (
      <div>
        <h4 className="text-muted tab-heading">
          <span>Parah (JUZ)</span>
        </h4>
        <div className="row sans-serif">
          <ul className="list-unstyled col-md-4">
            {Juz.slice(0, 10).map((item) => (
              <li key={item.id} className="list-item">
                <Link
                  className="list-item-link"
                  to={`/parah/${item.juz_number}`}
                >
                  <div className="row">
                    <div className="col-2 list-column text-muted">
                      {item.juz_number}
                    </div>
                    <div className="col-10 list-column font-weight-bold">
                      {item.juz_name}
                    </div>
                    <div className="col-10 list-column offset-2 text-right list-item-subtitle">
                      <span
                        className="text-uppercase"
                        style={{ fontSize: 18, fontFamily: "noorehira" }}
                      >
                        {item.juz_name_arabic}
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
          <ul className="list-unstyled col-md-4">
            {Juz.slice(10, 20).map((item) => (
              <li key={item.id} className="list-item">
                <Link
                  className="list-item-link"
                  to={`/parah/${item.juz_number}`}
                >
                  <div className="row">
                    <div className="col-2 list-column text-muted">
                      {item.juz_number}
                    </div>
                    <div className="col-10 list-column font-weight-bold">
                      {item.juz_name}
                    </div>
                    <div className="col-10 list-column offset-2 text-right list-item-subtitle">
                      <span
                        className="text-uppercase"
                        style={{ fontSize: 18, fontFamily: "noorehira" }}
                      >
                        {item.juz_name_arabic}
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
          <ul className="list-unstyled col-md-4">
            {Juz.slice(20, 30).map((item) => (
              <li key={item.id} className="list-item">
                <Link
                  className="list-item-link"
                  to={`/parah/${item.juz_number}`}
                >
                  <div className="row">
                    <div className="col-2 list-column text-muted">
                      {item.juz_number}
                    </div>
                    <div className="col-10 list-column font-weight-bold">
                      {item.juz_name}
                    </div>
                    <div className="col-10 list-column offset-2 text-right list-item-subtitle">
                      <span
                        className="text-uppercase"
                        style={{ fontSize: 18, fontFamily: "noorehira" }}
                      >
                        {item.juz_name_arabic}
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default index;
